// External Dependencies
import { CheckCircleIcon, XMarkIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useRouteLoaderData } from "@remix-run/react";

// Internal Dependencies
import TextLink from "~/components/ui/navigation/TextLink";

export default function Notifications({ type }) {
  const { churchSlug } = useRouteLoaderData("root");

  const notifyType = type?.split("-")?.[0];
  const messages = {
    account: {
      title: "Update Successful",
      description: "Your Account has been successfully updated.",
    },
    addDefaultTabs: {
      title: "Default Tabs Added",
      description: "You have successfully added the default tabs.",
    },
    addDefaultTVTab: {
      title: "Default TV Tab Added",
      description: "You have successfully added the default TV tab.",
    },
    addTab: {
      title: "Tab Added",
      description: "You have successfully added a tab.",
    },
    createApp: {
      title: "App Created",
      description: "You have successfully created a new app.",
    },
    createCollectionItem: {
      title: "Collection Created",
      description: "You have successfully created a new collection.",
    },
    updateCollection: {
      title: "Collection Updated",
      description: "You have successfully updated your collection.",
    },
    deleteCollection: {
      title: "Collection Deleted",
      description: "You have successfully deleted your collection.",
    },
    createContentItem: {
      title: "Content Item Created",
      description: "A new content item has been successfully created.",
    },
    createFeed: {
      title: "Feed Created",
      description: "A new feed has been successfully created.",
    },
    deleteContentItem: {
      title: "Content Item Deleted",
      description: "This content item has been successfully deleted.",
    },
    deleteFeed: {
      title: "Feed Deleted",
      description: "This feed has been successfully deleted.",
    },
    deleteTab: {
      title: "Tab Deleted",
      description: "You have successfully deleted a tab.",
    },
    duplicateContentItem: {
      title: "Content Item Duplicated",
      description: "You have successfully duplicated a content item.",
    },
    changeCollection: {
      title: "Collection Updated",
      description:
        "You have successfully moved this content item to another collection.",
    },
    newCommunity: {
      title: "Community Added",
      description: "You have successfully created a new community.",
    },
    updateCommunity: {
      title: "Community Updated",
      description: "You have successfully updated a community.",
    },
    deleteCommunity: {
      title: "Community Deleted",
      description: "This Community has been successfully deleted.",
    },
    updateTab: {
      title: "Tab Updated",
      description: "You have successfully updated a tab.",
    },
    updateTabOrder: {
      title: "Tab Order Updated",
      description: "You have successfully updated the order of the tabs.",
    },
    memberUpdated: {
      title: "Update Successful",
      description: "Member Account has been successfully updated.",
    },
    memberUpdateFailed: {
      title: "Update Failed",
      description: "Only admins can change user roles",
    },
    memberRemoved: {
      title: "Member Removed",
      description: "You have successfully removed a member.",
    },
    renameFeed: {
      title: "Rename Successful",
      description: "The feed was successfully renamed.",
    },
    userProfile: {
      title: "Update Successful",
      description: "Your User Profile has been successfully updated.",
    },
    orgProfile: {
      title: "Update Successful",
      description: "Your Organization Profile has been successfully updated.",
    },
    getHelp: {
      title: "Message Sent",
      description: "Our Apollos Success Team will respond to you via email.",
    },
    inviteMembers: {
      title: "Invite Emails Sent",
      link: `/${churchSlug}/users`,
      linkText: "View Team Members",
      description:
        "Your team members will be able to log in and help with the Setup Guide.",
    },
    deleteSection: {
      title: "Section Deleted",
      description: "You have successfully deleted a section.",
    },
    renameContentItem: {
      title: "Content Item Renamed",
      description: "You have successfully renamed a content item.",
    },
    saveFeature: {
      title: "Feature Updated",
      description: "You have successfully updated a feature.",
    },
    sectionsReordered: {
      title: "Section Order Updated",
      description: "Feed sections have been reordered.",
    },
    toggleIntegrationOn: {
      title: "Integration Enabled",
      description: "You have successfully enabled a integration.",
    },
    toggleIntegrationOff: {
      title: "Integration Disabled",
      description: "You have successfully disabled a integration.",
    },
    integrationUpdated: {
      title: "Update Successful",
      description: "Your integration has been successfully updated.",
    },
    marketingProfile: {
      title: "Update Successful",
      description: "Your marketing profile has been successfully updated.",
    },
    rockTestSuccess: {
      title: "Integration Successful",
      description: "Your integration to Rock is setup correctly",
    },
    rockTestFailure: {
      title: "Integration Failed",
      description: "Please check your API Key and URL and try again.",
      type: "error",
    },
    releaseRequest: {
      title: "Request Submitted",
      description:
        "Your request has been submitted. We will review and respond to you via email.",
    },
    updateTVTabFeed: {
      title: "TV Tab Feed Updated",
      description: "You have successfully updated the TV tab feed.",
    },
    failedReleaseRequest: {
      title: "Too Many Requests",
      description: "Please wait 15 minutes before submitting another request.",
    },
    templateAdded: {
      title: "Template Added",
      description: "You have successfully added a template.",
    },
    planUpdated: {
      title: "Plan Updated",
      description: "You have successfully updated a plan.",
    },
    accountFailed: {
      title: "Update Failed",
      description: "Please check your credentials and URL and try again.",
      type: "error",
    },
    failedDeleteTag: {
      title: "Delete Failed",
      description: "The tag you are trying to delete is in use.",
      type: "error",
    },
    successDeleteTag: {
      title: "Tag Deleted",
      description: "You have successfully deleted a tag.",
    },
    successUpdateTag: {
      title: "Tag Updated",
      description: "You have successfully updated a tag.",
    },
    successCreateTag: {
      title: "Tag Created",
      description: "You have successfully created a tag.",
    },
  };

  const id = `${notifyType}-notification-toast`;
  const messageLink = messages?.[notifyType]?.link;
  const messageType = messages?.[notifyType]?.type || 'primary';

  return (
    <div id={id} className="z-50 -mx-3.5 mb-1 md:w-auto md:p-4">
      <div className="mb-4 flex rounded-md bg-white p-2 drop-shadow-lg">
        <div className="flex justify-center">
          <div className={`flex h-[48px] w-[48px] items-center justify-center rounded-full bg-${messageType}-100`}>
            <div className={`flex h-[32px] w-[32px] items-center justify-center rounded-full bg-${messageType}-300`}>
              {messageType === 'error' ? (
                <XCircleIcon
                  className="h-6 w-6 text-error-600"
                  aria-hidden="true"
                />
              ):(
                <CheckCircleIcon
                  className="h-6 w-6 text-primary-600"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-3 ml-2">
          <div className="flex flex-col">
            <div className="text-sm font-bold text-gray-900">
              {messages?.[notifyType]?.title || "Success"}
            </div>
            <div className="mt-1 w-72 text-sm text-gray-600" role="alert">
              {messages?.[notifyType]?.description}
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div
              className="pointer-events-auto cursor-pointer text-sm font-semibold text-gray-600"
              onClick={() => (document.getElementById(id).className = "hidden")}
            >
              Dismiss
            </div>
            {messageLink ? <TextLink
                href={messageLink}
                styles="text-sm text-primary-700 font-semibold ml-4"
              >
                {messages?.[notifyType]?.linkText}
              </TextLink> : null}
          </div>
        </div>
        <div
          className="pointer-events-auto mt-2 mr-1 cursor-pointer"
          onClick={() => (document.getElementById(id).className = "hidden")}
        >
          <XMarkIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}
