// External Dependencies
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation,
} from "@remix-run/react";
import { metaV1 } from "@remix-run/v1-meta";
import { withSentry } from "@sentry/remix";
import { useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import "react-tooltip/dist/react-tooltip.css";

// Internal Dependencies
import "./app.css";
import { Provider } from "~/components/features/content/ClusterProvider";
import Notifications from "~/components/ui/display/Notifications";
import GlobalLoading from "~/components/ui/navigation/GlobalLoading";
import { useIntercom } from "~/hooks/useIntercom";
import { useKeyboardFocus } from "~/hooks/useKeyboardFocus";
import { useTracking } from "~/hooks/useTracking";
import { getDeltaDags } from "~/models/astronomer.server";
import {
  getChurchFromParams,
  getPlanByChurchSlug,
  getPlanSkillsByChurchSlug,
} from "~/models/church.server";
import {
  getActiveIntegrationsByChurchSlug,
  getAllEventCountByChurchSlug,
  getAmazonConfigByChurchSlug,
  getAndroidConfigByChurchSlug,
  getCampusesCount,
  getFeedsByChurchSlug,
  getIOSConfigByChurchSlug,
  getMarketingProfileByChurchSlug,
  getNonTvTabsByChurchSlug,
  getOrganizationProfileByChurchSlug,
  getRokuConfigByChurchSlug,
  getTvTabsByChurchSlug,
} from "~/models/cluster/cluster.server";
import { getOrganizationsByUser } from "~/models/organization.server";
import { getPlans } from "~/models/plan.server";
import { getAllReleaseRequests } from "~/models/releaseRequest.server";
import { getRoleNameByRoleId } from "~/models/role.server";
import { getTiptapCollabJWT, getTiptapJWT } from "~/models/tiptap.server";
import { getOfferingsByUserId, getSkillsByUserId } from "~/models/user.server";
import { commitSession, getSession, getUser } from "~/session.server";
import { calculatePercentages, getIntegrationsAccess } from "~/utils";

export const meta = (args) =>
  metaV1(args, {
    charset: "utf-8",
    title: "Apollos Admin",
    viewport: "width=device-width,initial-scale=1",
  });

export async function loader({ request, params }) {
  const session = await getSession(request);
  const user = await getUser(request);
  const churchSlug = await getChurchFromParams(params, user?.id);
  const church = await getOrganizationProfileByChurchSlug(churchSlug?.slug);
  const marketingProfile = await getMarketingProfileByChurchSlug(
    churchSlug?.slug
  );
  const integrations = await getActiveIntegrationsByChurchSlug(
    churchSlug?.slug
  );
  const offerings = await getOfferingsByUserId(user?.id, churchSlug?.slug);
  const amazonConfig = await getAmazonConfigByChurchSlug(churchSlug?.slug);
  const androidConfig = await getAndroidConfigByChurchSlug(churchSlug?.slug);
  const iosConfig = await getIOSConfigByChurchSlug(churchSlug?.slug);
  const rokuConfig = await getRokuConfigByChurchSlug(churchSlug?.slug);
  const organizations = await getOrganizationsByUser(user?.id);
  const tabs = await getNonTvTabsByChurchSlug(churchSlug?.slug);
  const tvTabs = await getTvTabsByChurchSlug(churchSlug?.slug);
  const feeds = await getFeedsByChurchSlug(churchSlug?.slug);
  const releases = await getAllReleaseRequests(churchSlug?.slug);
  const toastMessage = session.get("toastMessage") || null;
  const skills = await getSkillsByUserId(user?.id, churchSlug?.slug);
  const planSkills = await getPlanSkillsByChurchSlug(
    user?.id,
    churchSlug?.slug
  );
  const { allIntegrations } = getIntegrationsAccess(skills);
  let integrationsForPercentage = [];
  if (!allIntegrations) {
    integrationsForPercentage = undefined;
  } else {
    integrationsForPercentage = integrations;
  }

  const completionPercentages = calculatePercentages({
    androidConfig,
    iosConfig,
    offerings,
    orgProfile: church,
    releases,
    tabs,
    tvTabs,
    marketingProfile,
    integrationsForPercentage,
  });
  const tiptapToken = await getTiptapJWT();
  const tiptapCollabToken = await getTiptapCollabJWT();
  const plan = await getPlanByChurchSlug(churchSlug?.slug);
  const role = await getRoleNameByRoleId(user?.roleId);
  const plans = await getPlans();
  const eventsCount = await getAllEventCountByChurchSlug(churchSlug?.slug);
  const campusesCount = await getCampusesCount(churchSlug?.slug);

  // Don't let Astronomer timing out ruin our app.
  let deltaDags = [];
  try {
    deltaDags = await getDeltaDags(churchSlug?.slug);
  } catch (e) {
    console.error(e);
  }

  return json(
    {
      ENV: {
        AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
        CLOUDINARY_CLOUD_NAME: process.env.CLOUDINARY_CLOUD_NAME,
        CLOUDINARY_JSON_UPLOAD_PRESET:
          process.env.CLOUDINARY_JSON_UPLOAD_PRESET,
        CLOUDINARY_PDF_UPLOAD_PRESET: process.env.CLOUDINARY_PDF_UPLOAD_PRESET,
        CLOUDINARY_IMAGE_UPLOAD_PRESET:
          process.env.CLOUDINARY_IMAGE_UPLOAD_PRESET,
        ENABLE_CMS: process.env.ENABLE_CMS,
        ENABLE_PEOPLE: process.env.ENABLE_PEOPLE,
        ENABLE_EVENT_BLOCK: process.env.ENABLE_EVENT_BLOCK,
        ENABLE_NEW_WEB:
          church?.enableNewWeb ||
          process.env.ENABLE_NEW_WEB === "true" ||
          plan?.name === "genesis" ||
          plan?.name === "creator",
        ENABLE_NEW_EDITOR: process.env.ENABLE_NEW_EDITOR === "true",
        ENVIRONMENT: process.env.ENVIRONMENT,
        FRESHWORKS_WIDGET_ID: process.env.FRESHWORKS_WIDGET_ID,
        SEGMENT_API_KEY: process.env.SEGMENT_API_KEY,
        TIPTAP_AI_APP_ID: process.env.TIPTAP_AI_APP_ID,
        TIPTAP_AI_API_TOKEN: tiptapToken,
        TIPTAP_COLLAB_APP_TOKEN: tiptapCollabToken,
        USER: {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          church_slug: churchSlug?.slug,
        },
        APOLLOS_WEB_URL: process.env.APOLLOS_WEB_URL || "https://apollos.site",
      },
      amazonConfig,
      campusesCount,
      androidConfig,
      church,
      churchSlug: churchSlug?.slug || "church",
      completionPercentages,
      deltaDags,
      eventsCount,
      feeds,
      integrations,
      iosConfig,
      marketingProfile,
      offerings,
      organizations,
      orgProfile: church,
      params,
      plan: plan,
      planSkills,
      plans,
      releases,
      rokuConfig,
      role,
      skills,
      tabs,
      tvTabs,
      toastMessage,
      user,
    },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
}

function App() {
  const data = useLoaderData();
  const location = useLocation();
  const { trackPageView } = useTracking();
  useIntercom();
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  // Handle toast notifications
  const { churchSlug, toastMessage } = data;
  const toastRef = useRef();
  useEffect(() => {
    if (!toastMessage) return;
    if (toastRef.current !== toastMessage) {
      toast.custom(<Notifications type={toastMessage} />, { duration: 6000 });
    }
    toastRef.current = toastMessage;
  }, [toastMessage]);

  useKeyboardFocus();

  // Automatically track page views on route changes
  useEffect(() => {
    trackPageView({
      path: location.pathname,
      title: document.title,
    });
  }, [location, trackPageView]);

  return (
    <html
      lang="en"
      className={`antialiased h-full bg-gray-50 ${active ? "cursor-wait" : ""}`}
    >
      <head>
        <Meta />
        <Links />
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        ></script>
      </head>
      <body className="h-full">
        <GlobalLoading />
        <Toaster position="bottom-right" />

        <Provider churchSlug={churchSlug}>
          <Outlet />
        </Provider>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);
